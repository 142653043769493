import { Capacitor } from "@capacitor/core";
import * as Sentry from '@sentry/capacitor';
import { store } from '@stencil/redux';
import { getAppConfigEnvironmentName } from "../store/selectors";
import { App, AppInfo } from "@capacitor/app";
import { Build } from '@stencil/core';

const sentryEnabled = !Build.isDev;

export const setupSentry = async () => {

  if (!sentryEnabled) {
    return;
  }

  const info: AppInfo = Capacitor.isNativePlatform() ? await App.getInfo() : { version: 'web', build: 'web', name: 'Coreo', id: null };
  const state = store.getState();
  const environment = getAppConfigEnvironmentName(state)
  const release = `App_${info.version}-${info.build}`;

  Sentry.addEventProcessor(event => {
    event.release = release;
    event.environment = environment;
    return event;
  });
};

export const initSentry = () => sentryEnabled && Sentry.init({
  dsn: 'https://e625cb3e9eec46f3a2931441ad0eb2cb@o416754.ingest.sentry.io/5354545',
  normalizeDepth: 10
});

export default Sentry;
